<template>
    <div class="pr30">
        <!-- 主标题 -->
        <div class="ls_flex_center ls_aic mt20">
            <div
                class="plr6 fz18 lh26 c0"
                v-for="(item, index) in '选择模板创建插花'"
                :key="index"
            >
                {{ item }}
            </div>
        </div>
        <!-- 标题提示 -->
        <div class="ls_flex_center ls_aic">
            <div
                class="plr6 fz10 lh26 c9"
                v-for="(item, index) in 'Recommended Works'"
                :key="index"
            >
                {{ item }}
            </div>
        </div>

        <!-- 列表 -->
        <div class="mt30 ls_flex ls_flex_wrap">
            <div
                class="wp19 p12 b-solid br8 box-bb ls_p_rel ls_ovh ls_flex_sk mb14 ls_cp hover-item bgcf"
                v-for="(item, index) in list"
                :key="index"
                @click="toEditDetail(item)"
            >
                <img class="wp100 mb10" :src="item.coverImage | imgFormat" />

                <div class="fz14 lh30 h30 c0">{{ item.name }}</div>
                <div class="ls_flex_between ls_aic">
                    <div class="fz12 ls_nowrap c6">{{ item.userName }}</div>

                    <div class="ls_flex ls_aic">
                        <img
                            class="mr10"
                            src="https://lvshuhualv.oss-cn-hangzhou.aliyuncs.com/plat/index/shoucang2.png"
                        />
                        <div class="fz12 c6">{{ item.likeCount }}</div>
                    </div>
                </div>

                <!-- 浮动悬浮按钮区域 -->
                <div
                    class="ls_p_abs l0 t0 h38 wp100 ls_flex_around ls_aic box-bb bgc08 hover-box"
                >
                    <div
                        class="cf ls_cp pl30 ls_flex_center"
                        @click.stop="share(item)"
                    >
                        <i class="iconfont icon-fenxiang mr6"></i>
                        <span class="fz14">分享</span>
                    </div>
                    <div class="bgcf w1 h20"></div>
                    <div
                        class="cf ls_cp pr30 ls_flex_center"
                        @click.stop="viewMaterials(item)"
                    >
                        <i class="iconfont icon-tiezi mr6" />
                        <span class="fz14">用料</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="ls_flex_center mt30">
            <el-pagination
                v-show="total > 0"
                :current-page="params.pageIndex"
                :page-sizes="[10, 15, 20, 25]"
                :page-size="params.pageSize"
                :total="total"
                background
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </div>
    </div>
</template>

<script>
import Vue from "vue";

export default {
    data() {
        return {
            value1: false,
            activeName: "all",

            list: [],
            params: {
                pageIndex: 1,
                pageSize: 10,
                type: 1,
            },
            total: 0,
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            this.$ajax
                .post("/product/expand/queryList", this.params)
                .then(res => {
                    this.list = res.list;
                    this.total = res.total;
                });
        },
        handleSizeChange(val) {
            this.params.pageIndex = 1;
            this.params.pageSize = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.params.pageIndex = val;
            this.getList();
        },
        toEditDetail(item) {
            window.open(
                this.$editAddr + "?productExpandId=" + item.id
            );

            // 点赞
            this.$ajax
                .post("/product/expand/like", {
                    productExpandId: item.id,
                    likeType: 1,
                })
                .then(() => {
                    setTimeout(this.getList, 1000);
                });
        },
        // 分享
        share(item) {
            this.$ajax
                .get("/product/createShareCode", {
                    params: { id: item.productId },
                })
                .then(res => {
                    this.$confirm(
                        <div>
                            <img
                                class="w300 h300"
                                src={Vue.filter("imgFormat")(res.data)}
                            />
                            <div class="mt25 fz18">
                                微信扫一扫，三维作品发送手机
                            </div>
                        </div>,
                        "",
                        {
                            showCancelButton: false,
                            showConfirmButton: false,
                            dangerouslyUseHTMLString: true,
                            center: true,
                        }
                    );
                });
        },
        // 查看用料
        viewMaterials(item) {
            let dom = "";
            if (!item.useMaterials) {
                this.$message.warning("暂无用料信息");
                return;
            }

            item.useMaterials.map(item => {
                dom += `<div class="ls_flex ls_aic">
                    <span class="lh26 fz14 cf21 mr10">⬤</span>
                    <span class="lh26 fz14">
                        ${item.materialName} x${item.count}
                    </span>
                </div>`;
            });

            this.$confirm(`<div>${dom}</div>`, "用料详情", {
                showCancelButton: false,
                showConfirmButton: false,
                dangerouslyUseHTMLString: true,
                width: "400px",
            });
        },
    },
};
</script>
